const USCountryCode: ICountrySelectData = {
  name: "US (+1)",
  value: "US",
};

const IndiaCountryCode: ICountrySelectData = {
  name: "India (+91)",
  value: "IN",
};

export interface ICountrySelectData {
  name: string;
  value: string;
}

export const allCountryList = [USCountryCode, IndiaCountryCode];

export const supportedCountryList = [USCountryCode];
