import React from "react";
import {
  ToastContainer,
  toast,
  ToastContainerProps,
  Zoom,
} from "react-toastify";

interface AbridgeToastProps extends ToastContainerProps {
  id?: string;
}

/**
 * Abridge stylized react-toastify component to integrate toast messages in any app.
 */
const AbridgeToastContainer: React.FC<AbridgeToastProps> = ({ ...props }) => {
  return (
    <ToastContainer
      hideProgressBar={true}
      position={"bottom-left"}
      closeOnClick={false}
      pauseOnHover={true}
      closeButton={false}
      draggable={false}
      autoClose={3000}
      transition={Zoom}
      limit={3}
      {...props}
    />
  );
};

export const sendToast = toast;
export default AbridgeToastContainer;
