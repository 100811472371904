import { encounter } from "@abridge/encounter-schema";
import { z } from "zod";

export const CreateEncounterBodyDto = z.object({
  appointment: encounter.DisplayAppointment.optional(),
  startDate: z.string().datetime(),
  description: z.string().optional(),
  userAgent: z.string().optional(),
});

export type CreateEncounterBodyDto = z.infer<typeof CreateEncounterBodyDto>;

export const EncounterId = z.string().uuid().brand("EncounterId");

export type EncounterId = z.infer<typeof EncounterId>;

export const RsaKey = z.string().base64().brand("PublicKey");

export type RsaKey = z.infer<typeof RsaKey>;

export const CreateEncounterResponseDto = z.object({
  encounterId: EncounterId,
  publicKey: RsaKey,
});

export type CreateEncounterResponseDto = z.infer<
  typeof CreateEncounterResponseDto
>;
