import { logAmplitude } from "@integrations/amplitude";
import { useState, useEffect } from "react";

const useNetworkStatus = (): boolean => {
  const [isOnline, setIsOnline] = useState<boolean>(() =>
    typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
      ? navigator.onLine
      : true,
  );

  useEffect(() => {
    function setOnline() {
      setIsOnline(true);
      logAmplitude("SOAP_DASHBOARD_NETWORK_STATUS", {
        status: "online",
      });
    }

    function setOffline() {
      setIsOnline(false);
      logAmplitude("SOAP_DASHBOARD_NETWORK_STATUS", {
        status: "offline",
      });
    }

    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);

  return isOnline;
};

export default useNetworkStatus;
