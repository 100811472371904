import { StorageQuotaLevel } from "./types";
import { useEffect, useState } from "react";
import { EventEmitter } from "events";
import StrictEventEmitter from "strict-event-emitter-types";
import { isDevelopment } from "@constants";
import { sentryError } from "@integrations/sentry";
import { KnownFlagKey, useKnownFeatureFlag } from "@hooks/useKnownFeatureFlag";

const MiB = 1024 * 1024;

/**
 * Below this amount of storage, it's too risky to allow any write access to
 * IndexedDB -- we don't want to risk corrupting whatever is already there.
 */
export const MIN_STORAGE_QUOTA_BYTES = 100 * MiB;

export const DEFAULT_MIN_RECORDING_STORAGE_QUOTA_MIB = 1024;

/**
 * How often to check the storage quota.
 */
const QUOTA_CHECK_INTERVAL_MS = 5 * 1000; // 5 seconds

export type CheckStorageQuotaResult = {
  level: StorageQuotaLevel;
  estimate: StorageEstimate | null;
};

/**
 * Validates the low storage threshold value.
 *
 * If valid, returns the value.
 *
 * If invalid:
 * - In production, logs an error to Sentry and returns the default.
 * - In development, throws the error.
 */
const validateLowStorageThresholdBytes = (untrustedValue: number): number => {
  if (
    Number.isSafeInteger(untrustedValue) &&
    untrustedValue >= MIN_STORAGE_QUOTA_BYTES
  ) {
    return untrustedValue;
  }
  const error = new RangeError(
    `expected integer >= ${MIN_STORAGE_QUOTA_BYTES}; got ${untrustedValue}`,
  );
  if (isDevelopment) {
    throw error;
  }
  sentryError(error);
  return DEFAULT_MIN_RECORDING_STORAGE_QUOTA_MIB * MiB;
};

/**
 * Check if the user has enough storage quota to record.
 */
export const checkStorageQuota = async (
  lowStorageThresholdBytes: number,
): Promise<CheckStorageQuotaResult> => {
  // Important to do a sanity check here because it's easy to mix up units.
  const validatedLowStorageThresholdBytes = validateLowStorageThresholdBytes(
    lowStorageThresholdBytes,
  );
  try {
    const estimate = await navigator.storage.estimate();
    const remaining = (estimate.quota ?? 0) - (estimate.usage ?? 0);
    if (remaining < MIN_STORAGE_QUOTA_BYTES) {
      return { level: "noStorage", estimate };
    }
    if (remaining < validatedLowStorageThresholdBytes) {
      return { level: "lowStorage", estimate };
    }
    return { level: "sufficientStorage", estimate };
  } catch (e) {
    return { level: "noStorage", estimate: null };
  }
};

export type CheckStorageQuota = typeof checkStorageQuota;

type StorageQuotaEventListener = (storageLevel: StorageQuotaLevel) => void;

type StorageQuotaEvents = {
  quota: StorageQuotaEventListener;
};

type StorageQuotaEventEmitter = StrictEventEmitter<
  EventEmitter,
  StorageQuotaEvents
>;

/**
 * Exported for testing only.
 */
export class StorageQuotaBroadcaster {
  private readonly emitter: StorageQuotaEventEmitter = new EventEmitter();

  private _lowStorageThresholdBytes: number;
  private timerId: NodeJS.Timeout | null = null;
  private quotaState: StorageQuotaLevel = "sufficientStorage";

  /**
   * @param lowStorageThresholdBytes The minimum amount of storage required to
   *    safely start or resume a recording.
   * @param checkQuota Replaceable for testing.
   */
  constructor(
    lowStorageThresholdBytes: number,
    private readonly checkQuota: CheckStorageQuota = checkStorageQuota,
  ) {
    this._lowStorageThresholdBytes = validateLowStorageThresholdBytes(
      lowStorageThresholdBytes,
    );
    // Get the initial state asynchronously.
    this.pollOnce();
  }

  public get lowStorageThresholdBytes(): number {
    return this._lowStorageThresholdBytes;
  }

  public set lowStorageThresholdBytes(lowStorageThresholdBytes: number) {
    const validatedValue = validateLowStorageThresholdBytes(
      lowStorageThresholdBytes,
    );
    if (validatedValue !== this._lowStorageThresholdBytes) {
      this._lowStorageThresholdBytes = validatedValue;
      this.pollOnce();
    }
  }

  public addEventListener(listener: StorageQuotaEventListener): void {
    this.emitter.addListener("quota", listener);
    this.updatePolling();
    // Provide the current state immediately.
    listener(this.quotaState);
  }

  public removeEventListener(listener: StorageQuotaEventListener): void {
    this.emitter.removeListener("quota", listener);
    this.updatePolling();
  }

  private pollOnce() {
    this.checkQuota(this.lowStorageThresholdBytes).then(({ level }) => {
      if (level !== this.quotaState) {
        this.quotaState = level;
        this.emit();
      }
    });
  }

  private updatePolling() {
    const listenerCount = this.emitter.listenerCount("quota");
    if (listenerCount === 0) {
      this.stopPolling();
    } else {
      this.startPolling();
    }
  }

  private startPolling() {
    if (this.timerId) {
      return;
    }
    this.timerId = setInterval(() => this.pollOnce(), QUOTA_CHECK_INTERVAL_MS);
  }

  private stopPolling() {
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
  }

  private emit() {
    this.emitter.emit("quota", this.quotaState);
  }
}

/**
 * Singleton broadcaster for storage quota events.
 */
let broadcaster: StorageQuotaBroadcaster | null = null;

const getBroadcaster = (
  lowStorageThresholdBytes: number,
): StorageQuotaBroadcaster => {
  if (!broadcaster) {
    broadcaster = new StorageQuotaBroadcaster(lowStorageThresholdBytes);
  }
  broadcaster.lowStorageThresholdBytes = lowStorageThresholdBytes;
  return broadcaster;
};

export type StorageQuotaBroadcasterProvider = typeof getBroadcaster;

/**
 * Hook to monitor the current storage quota level.
 *
 * @param broadcasterProvider Replaceable for testing.
 */
export const useStorageQuotaLevel = (
  broadcasterProvider: StorageQuotaBroadcasterProvider = getBroadcaster,
): StorageQuotaLevel => {
  const [storageQuotaLevel, setStorageQuotaLevel] =
    useState<StorageQuotaLevel>("sufficientStorage");

  const { value: minRecordingStorageMib } = useKnownFeatureFlag(
    KnownFlagKey.WebRecordingMinimumRecordingStorageMib,
    DEFAULT_MIN_RECORDING_STORAGE_QUOTA_MIB,
  );

  useEffect(() => {
    // The flag is specified in MiB for human readability in the Launch Darkly
    // UI, but we use bytes everywhere in the code.
    const lowStorageThresholdBytes = minRecordingStorageMib * MiB;
    const listener = (level: StorageQuotaLevel) => {
      setStorageQuotaLevel(level);
    };
    broadcasterProvider(lowStorageThresholdBytes).addEventListener(listener);
    return () => {
      broadcasterProvider(lowStorageThresholdBytes).removeEventListener(
        listener,
      );
    };
  }, [minRecordingStorageMib]);

  return storageQuotaLevel;
};
