import { Icon } from "@components/rebrand/Icon";
import { sentryError } from "@integrations/sentry";
import { Component, ErrorInfo, ReactNode } from "react";
import styles from "./ErrorBoundary.module.scss";
interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const context = {
      extra: {
        errorInfo,
      },
    };
    sentryError(error, context);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public render() {
    if (this.state.hasError) {
      return (
        <div className={styles["error-boundary-container"]}>
          <div className={styles["note-editor-failed-container"]}>
            <div>
              <Icon
                className={styles["error-failed-icon"]}
                type="error-outline"
                size="xxxl"
                color="error"
              />
            </div>
            <div className={styles["error-header-text"]}>
              Something unexpected happened.
            </div>
            <div className={styles["error-failed-text"]}>
              Please reload the page and try again.
              <br />
              <br /> If you continue to receive this message, please{" "}
              <span className={styles["error-failed-con-sup"]}>
                <a href="mailto:help@abridge.com">contact support</a>
              </span>
              .
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
