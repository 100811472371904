import { ActiveRecordingState } from "@abridge/soap-common";
import { AudioMimeType } from "@contexts/recording/types";

export type UploaderVersion = "1" | "2";

export interface Recording {
  encounterId: string;
  recordingStatus: ActiveRecordingState["status"];
  uploadStatus: RecordingUploadStatus;
  recordingTimestamp: number;
  startTime: string;
  tabId: string;
  browserId?: string;
  userId?: string;
  pubKey: string;
  retryCount: number;
  endTime?: string;
  createdOn?: string;
  updatedOn?: string;
  uploaderVersion?: UploaderVersion;
}

export enum RecordingUploadStatus {
  PENDING = "pending",
  UPLOADED = "uploaded",
  ENCRYPTING = "encrypting",
  ENCRYPTION_FAILED = "encryption-failed", // terminal error state
  UPLOADING = "uploading",
  FAILED = "failed",
  DELETED = "deleted",
}

export interface RecordingChunk {
  userId?: string;
  encounterId: string;
  chunkId: string;
  chunkSeq: string;
  mimeType: AudioMimeType;
  /**
   * @deprecated superseded by {@link chunkDataBuffer}
   */
  chunkData?: string;
  chunkDataBuffer?: ArrayBuffer;
  encKey?: string;
  encSign?: {
    data: string;
    keys: string;
  };
  uploadStatus: RecordingUploadStatus;
  retryCount: number;
  isLastChunk: boolean;
  createdOn?: string;
  updatedOn?: string;
  uploaderVersion?: UploaderVersion;
}

export interface Identifier {
  key: "browser";
  value: string;
}
