/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty */

import { isClientSide } from "@utils/common";
import { sentryError } from "./sentry";
import type * as Braze from "@braze/web-sdk";
import { isDevelopment } from "@constants/index";

let braze: typeof Braze | undefined;

const initBraze = (): void => {
  try {
    if (!isClientSide()) return;
    if (!process.env.NEXT_PUBLIC_BRAZE_CLIENT_KEY) {
      console.log("braze not initialized");
      return;
    }
    braze = require("@braze/web-sdk");
    braze?.initialize(process.env.NEXT_PUBLIC_BRAZE_CLIENT_KEY, {
      baseUrl: "sdk.iad-02.braze.com",
      enableLogging: isDevelopment,
      doNotLoadFontAwesome: true,
    });
    braze?.automaticallyShowInAppMessages?.();
  } catch (err) {
    sentryError(err);
  }
};

const setBrazeUser = (uid: string) => {
  try {
    if (!isClientSide()) return;
    if (!uid) return;

    braze?.automaticallyShowInAppMessages?.();
    braze?.changeUser?.(uid);
    braze?.openSession?.();
  } catch (err) {
    console.error(err);
  }
};

type BrazeEventKey =
  | "SOAP_DASHBOARD_COMPLETE_NOTE_CLICKED"
  | "SOAP_DASHBOARD_EDITOR_HOVERED"
  | "SOAP_DASHBOARD_ONBOARDING_COMPLETED"
  | "SOAP_DASHBOARD_WR_VIRTUAL_RECORDING_ENABLED"
  | "SOAP_DASHBOARD_USER_LOGIN";

const logBrazeEvent = (eventName: BrazeEventKey, eventProperties = {}) => {
  try {
    if (!isClientSide()) return;
    if (!eventName) return;
    braze?.logCustomEvent?.(eventName, eventProperties);
  } catch (err) {}
};

const changeEmailNotificationSubscriptionType = (subscribed = true) => {
  if (!isClientSide() || !braze) return;
  try {
    braze
      ?.getUser()
      ?.setEmailNotificationSubscriptionType(
        subscribed ? "opted_in" : "unsubscribed",
      );
  } catch (err) {}
};

// Open a braze event at login and send login event to braze. Timeout to fix in-app message not being displayed on login trigger.
const openBrazeSessionAndSendLoginEvent = (uid: string) => {
  try {
    if (!isClientSide() || !braze) return;

    setBrazeUser(uid);

    setTimeout(() => {
      logBrazeEvent("SOAP_DASHBOARD_USER_LOGIN");
    }, 2000);
  } catch (err) {}
};

const clearBrazeSession = (): void => {
  try {
    if (!isClientSide() || !braze) return;
    braze?.requestImmediateDataFlush();
    braze?.wipeData?.();
  } catch (err) {}
};

export {
  initBraze,
  setBrazeUser,
  logBrazeEvent,
  changeEmailNotificationSubscriptionType,
  clearBrazeSession,
  openBrazeSessionAndSendLoginEvent,
};
