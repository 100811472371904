import {
  UseWebRecordingSupportArgs,
  WebRecordingSupport,
  WebRecordingUnsupportedStaticReason,
} from "./types";
import {
  DEFAULT_MIN_RECORDING_STORAGE_QUOTA_MIB,
  useStorageQuotaLevel,
} from "./storage";
import {
  checkStaticSupport,
  logCanStartNewRecording,
  logSupport,
} from "./startup";
import { useEffect, useState } from "react";
import useNetworkStatus from "@hooks/useNetworkStatus";
import { KnownFlagKey, useKnownFeatureFlag } from "@hooks/useKnownFeatureFlag";

/**
 * Hook to check if the current user's environment supports web recording. This
 * is meant to be used as a singleton in `UserContext`; all others should obtain
 * the value from there.
 *
 * Observability:
 * - On startup, sends Sentry events for unsupported environments.
 * - On startup, sends `SOAP_DASHBOARD_WR_DISABLED` if web recording is
 *   disabled (see {@link logSupport}).
 * - Whenever the ability to start a new recording changes, sends
 *   `SOAP_DASHBOARD_WR_NEW_RECORDINGS_ENABLED` or
 *   `SOAP_DASHBOARD_WR_NEW_RECORDINGS_DISABLED`.
 *
 * @param args
 * @param additionalDeps Optional additional deps to trigger re-evaluation.
 */
export const useWebRecordingSupport = <
  TAdditionalDeps extends ReadonlyArray<unknown> = ReadonlyArray<unknown>,
>(
  args: UseWebRecordingSupportArgs,
  additionalDeps?: TAdditionalDeps,
): WebRecordingSupport => {
  const [staticReason, setStaticReason] =
    useState<WebRecordingUnsupportedStaticReason | null>("unitialized");

  const { value: webRecordingFeatureFlag } = useKnownFeatureFlag(
    KnownFlagKey.WebRecording,
    false,
  );

  const { value: minRecordingStorageMib } = useKnownFeatureFlag(
    KnownFlagKey.WebRecordingMinimumRecordingStorageMib,
    DEFAULT_MIN_RECORDING_STORAGE_QUOTA_MIB,
  );

  const storageQuotaLevel = useStorageQuotaLevel();
  const isOnline = useNetworkStatus();

  useEffect(() => {
    checkStaticSupport({
      ...args,
      webRecordingFeatureFlag,
      minRecordingStorageMib,
    }).then(({ reason, storage }) => {
      logSupport({ reason, storage });
      setStaticReason(reason);
    });
  }, [
    webRecordingFeatureFlag,
    minRecordingStorageMib,
    setStaticReason,
    ...(additionalDeps || []),
  ]);

  const support = new WebRecordingSupport({
    reason: staticReason,
    online: isOnline,
    storageQuotaLevel,
  });

  // Record amplitude event when new recordings are enabled/disabled
  useEffect(() => {
    void logCanStartNewRecording(support);
  }, [support.canStartNewRecording]);

  return support;
};
