import FrontChatWidget, {
  FrontChatAPI,
  FrontChatInit,
  openFrontChat,
  hideFrontChatWidget,
  revealFrontChatWidget,
} from "./FrontChatWidget";

export default FrontChatWidget;
export {
  FrontChatAPI,
  FrontChatInit,
  openFrontChat,
  hideFrontChatWidget,
  revealFrontChatWidget,
};
