import React from "react";
import { clsx } from "clsx";
import styles from "./Icon.module.scss";
import AccountCircle from "public/rebrand/icons/account-circle-large.svg?svgr";
import AccountFilled from "public/rebrand/icons/account-large-filled.svg?svgr";
import AccountOutline from "public/rebrand/icons/account-large-outline.svg?svgr";
import Add from "public/rebrand/icons/add-large.svg?svgr";
import Alert from "@public/rebrand/icons/alert-large.svg?svgr";
import ArrowDown from "public/rebrand/icons/arrow-down-large.svg?svgr";
import ArrowLeft from "public/rebrand/icons/arrow-left-large.svg?svgr";
import ArrowRight from "public/rebrand/icons/arrow-right-large.svg?svgr";
import ArrowUp from "public/rebrand/icons/arrow-up-large.svg?svgr";
import BellFilled from "public/rebrand/icons/bell-large-filled.svg?svgr";
import BellOutlined from "public/rebrand/icons/bell-large-outline.svg?svgr";
import Billing from "@public/rebrand/icons/billing-large.svg?svgr";
import Browser from "public/rebrand/icons/browser-large.svg?svgr";
import Calendar from "public/rebrand/icons/calendar-large.svg?svgr";
import CalendarToday from "public/rebrand/icons/calendar-today-large.svg?svgr";
import Capture from "@public/rebrand/icons/capture.svg?svgr";
import ChatOutline from "@public/rebrand/icons/chat-large-outline.svg?svgr";
import Check from "public/rebrand/icons/check-large.svg?svgr";
import CheckboxChecked from "@public/rebrand/icons/checkbox-checked-large.svg?svgr";
import CheckboxFilled from "@public/rebrand/icons/checkbox-filled-large.svg?svgr";
import CheckboxUnchecked from "@public/rebrand/icons/checkbox-unchecked-large.svg?svgr";
import ChevronDown from "public/rebrand/icons/chevron-down-large.svg?svgr";
import ChevronLeft from "public/rebrand/icons/chevron-left-large.svg?svgr";
import ChevronRight from "public/rebrand/icons/chevron-right-large.svg?svgr";
import ChevronUp from "public/rebrand/icons/chevron-up-large.svg?svgr";
import Clock from "public/rebrand/icons/clock-large.svg?svgr";
import Close from "public/rebrand/icons/close-bold-large.svg?svgr";
import CloudSuccess from "public/rebrand/icons/cloud-success.svg?svgr";
import CollapseLeft from "public/rebrand/icons/collapse-left-medium.svg?svgr";
import CollapseRight from "public/rebrand/icons/collapse-right-medium.svg?svgr";
import Copy from "public/rebrand/icons/copy-large.svg?svgr";
import CopyChecked from "@public/rebrand/icons/copy-checked-large.svg?svgr";
import Delete from "public/rebrand/icons/delete-large.svg?svgr";
import DoneFilterHide from "@public/rebrand/icons/done-filter-hide.svg?svgr";
import DoneFilterShow from "@public/rebrand/icons/done-filter-show.svg?svgr";
import Edit from "public/rebrand/icons/edit-large.svg?svgr";
import ErrorOutline from "public/rebrand/icons/error-large-outline.svg?svgr";
import Groups from "@public/rebrand/icons/groups-large.svg?svgr";
import InfoFilled from "@public/rebrand/icons/info-large-filled.svg?svgr";
import InfoOutline from "@public/rebrand/icons/info-large-outline.svg?svgr";
import Learn from "public/rebrand/icons/learn-large.svg?svgr";
import Menu from "public/rebrand/icons/menu-large.svg?svgr";
import Mic from "public/rebrand/icons/mic-large-filled.svg?svgr";
import MicOutlined from "public/rebrand/icons/mic-large-outline.svg?svgr";
import NoteAddOutline from "public/rebrand/icons/note-add-large-outline.svg?svgr";
import NoteOutline from "public/rebrand/icons/note-large-outline.svg?svgr";
import NoteStacked from "public/rebrand/icons/note-stacked-large.svg?svgr";
import NotStarted from "@public/rebrand/icons/not-started.svg?svgr";
import PauseFilled from "public/rebrand/icons/pause-large.svg?svgr";
import PauseOutline from "public/rebrand/icons/pause-large-outline.svg?svgr";
import PlayFilled from "public/rebrand/icons/play-large-filled.svg?svgr";
import Progress from "public/rebrand/icons/progress.svg?svgr";
import RecordFilled from "@public/rebrand/icons/record-xlarge.svg?svgr";
import RecordLoadingFilled from "@public/rebrand/icons/record-loading-xlarge.svg?svgr";
import ReferGraphic from "@public/rebrand/icons/refer-large.svg?svgr";
import Search from "public/rebrand/icons/search-large.svg?svgr";
import SendPlane from "@public/rebrand/icons/send-large.svg?svgr";
import Settings from "@public/rebrand/icons/settings-large.svg?svgr";
import SortAscending from "@public/rebrand/icons/sort-large-earliest-first.svg?svgr";
import SortDescending from "@public/rebrand/icons/sort-large-latest-first.svg?svgr";
import Spinner from "@public/rebrand/icons/spinner-large.svg?svgr";
import StarFilled from "@public/rebrand/icons/star-filled.svg?svgr";
import StarOutline from "@public/rebrand/icons/star-outline.svg?svgr";
import Syncing from "public/rebrand/icons/syncing.svg?svgr";
import ThemeDark from "@public/rebrand/icons/theme-dark-large.svg?svgr";
import ThemeLight from "@public/rebrand/icons/theme-light-large.svg?svgr";
import ThumbsDown from "@public/rebrand/icons/thumb-down.svg?svgr";
import ThumbsUp from "@public/rebrand/icons/thumb-up.svg?svgr";
import WarningOutline from "public/rebrand/icons/warning-large-outline.svg?svgr";

export type IconSizes = "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl";

export type IconColors =
  | "accent-bold"
  | "accent"
  | "brand"
  | "currentColor"
  | "error"
  | "inverted"
  | "logo"
  | "primary"
  | "secondary"
  | "success"
  | "tertiary";

export const ICON_TYPES = [
  "account-circle",
  "account-filled",
  "account-outline",
  "add",
  "alert",
  "arrow-down",
  "arrow-left",
  "arrow-right",
  "arrow-up",
  "bell-filled",
  "bell-outlined",
  "billing",
  "browser",
  "calendar-today",
  "calendar",
  "capture",
  "chat-outline",
  "check",
  "checkbox-checked",
  "checkbox-filled",
  "checkbox-unchecked",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "clock",
  "close",
  "collapse-left",
  "collapse-right",
  "copy-checked",
  "copy",
  "cloud-success",
  "delete",
  "done-filter-hide",
  "done-filter-show",
  "edit",
  "error-outline",
  "groups",
  "info-filled",
  "info-filled",
  "info-outline",
  "info-outline",
  "learn",
  "menu",
  "mic",
  "mic-outlined",
  "not-started",
  "note-add-outline",
  "note-outline",
  "note-stacked",
  "pause-filled",
  "pause-outline",
  "play-filled",
  "progress",
  "record-filled",
  "record-loading-filled",
  "refer",
  "search",
  "send-plane",
  "send-plane",
  "settings",
  "sort-asc",
  "sort-desc",
  "spinner",
  "star-filled",
  "star-outline",
  "syncing",
  "theme-dark",
  "theme-light",
  "thumbs-down",
  "thumbs-up",
  "warning-outline",
] as const;

export type IconTypes = (typeof ICON_TYPES)[number];

export interface IconProps {
  /**
   * The type of the icon
   */
  type: IconTypes;

  /**
   * The color of the icon
   */
  color?: IconColors;

  /**
   * The size of the icon, i.e. sm, md, lg, xl, xxl
   */
  size?: IconSizes;

  className?: string;
}

const sizeMap: Record<IconSizes, string> = {
  sm: "var(--spacing-sm)",
  md: "var(--spacing-md)",
  lg: "var(--spacing-lg)",
  xl: "var(--spacing-xl)",
  xxl: "var(--spacing-xxl)",
  xxxl: "var(--spacing-xxxl)",
};

export const Icon: React.FC<IconProps> = ({
  type,
  color = "primary",
  size = "md",
  className,
}) => {
  const cssColor = color === "currentColor" ? color : `var(--icon-${color})`;
  const cssSize = sizeMap[size];

  const style: React.CSSProperties = {
    color: cssColor !== "currentColor" ? cssColor : undefined,
    width: cssSize,
  };

  const props = {
    className,
    style,
  };

  switch (type?.toLowerCase?.()) {
    case "account-circle":
      return <AccountCircle {...props} />;
    case "delete":
      return <Delete {...props} />;
    case "arrow-down":
      return <ArrowDown {...props} />;
    case "arrow-up":
      return <ArrowUp {...props} />;
    case "arrow-left":
      return <ArrowLeft {...props} />;
    case "arrow-right":
      return <ArrowRight {...props} />;
    case "calendar":
      return <Calendar {...props} />;
    case "edit":
      return <Edit {...props} />;
    case "error-outline":
      return <ErrorOutline {...props} />;
    case "capture":
      return <Capture {...props} />;
    case "check":
      return <Check {...props} />;
    case "bell-filled":
      return <BellFilled {...props} />;
    case "bell-outlined":
      return <BellOutlined {...props} />;
    case "copy":
      return <Copy {...props} />;
    case "copy-checked":
      return <CopyChecked {...props} />;
    case "chevron-left":
      return <ChevronLeft {...props} />;
    case "chevron-right":
      return <ChevronRight {...props} />;
    case "cloud-success":
      return <CloudSuccess {...props} />;
    case "add":
      return <Add {...props} />;
    case "account-filled":
      return <AccountFilled {...props} />;
    case "account-outline":
      return <AccountOutline {...props} />;
    case "alert":
      return <Alert {...props} />;
    case "billing":
      return <Billing {...props} />;
    case "browser":
      return <Browser {...props} />;
    case "calendar-today":
      return <CalendarToday {...props} />;
    case "chat-outline":
      return <ChatOutline {...props} />;
    case "checkbox-checked":
      return <CheckboxChecked {...props} />;
    case "checkbox-filled":
      return (
        <CheckboxFilled
          {...props}
          className={clsx(styles["checkbox-filled"], props.className)}
        />
      );
    case "checkbox-unchecked":
      return <CheckboxUnchecked {...props} />;
    case "chevron-down":
      return <ChevronDown {...props} />;
    case "chevron-up":
      return <ChevronUp {...props} />;
    case "close":
      return <Close {...props} />;
    case "collapse-left":
      return <CollapseLeft {...props} />;
    case "collapse-right":
      return <CollapseRight {...props} />;
    case "done-filter-hide":
      return <DoneFilterHide {...props} />;
    case "done-filter-show":
      return <DoneFilterShow {...props} />;
    case "groups":
      return <Groups {...props} />;
    case "info-filled":
      return <InfoFilled {...props} />;
    case "info-outline":
      return <InfoOutline {...props} />;
    case "learn":
      return <Learn {...props} />;
    case "menu":
      return <Menu {...props} />;
    case "mic":
      return <Mic {...props} />;
    case "mic-outlined":
      return <MicOutlined {...props} />;
    case "note-add-outline":
      return <NoteAddOutline {...props} />;
    case "note-outline":
      return <NoteOutline {...props} />;
    case "note-stacked":
      return <NoteStacked {...props} />;
    case "not-started":
      return <NotStarted {...props} />;
    case "pause-filled":
      return <PauseFilled {...props} />;
    case "play-filled":
      return <PlayFilled {...props} />;
    case "progress":
      return <Progress {...props} />;
    case "search":
      return <Search {...props} />;
    case "send-plane":
      return <SendPlane {...props} />;
    case "settings":
      return <Settings {...props} />;
    case "sort-asc":
      return <SortAscending {...props} />;
    case "sort-desc":
      return <SortDescending {...props} />;
    case "spinner":
      return <Spinner {...props} />;
    case "star-filled":
      return <StarFilled {...props} />;
    case "star-outline":
      return <StarOutline {...props} />;
    case "syncing":
      return <Syncing {...props} />;
    case "thumbs-down":
      return <ThumbsDown {...props} />;
    case "record-filled":
      return <RecordFilled {...props} />;
    case "record-loading-filled":
      return <RecordLoadingFilled {...props} />;
    case "theme-dark":
      return <ThemeDark {...props} />;
    case "theme-light":
      return <ThemeLight {...props} />;
    case "thumbs-up":
      return <ThumbsUp {...props} />;
    case "pause-outline":
      return <PauseOutline {...props} />;
    case "clock":
      return <Clock {...props} />;
    case "refer":
      return <ReferGraphic {...props} />;
    case "warning-outline":
      return <WarningOutline {...props} />;
    default:
      return null;
  }
};
