import { MLGenerationStatus } from "@abridge/soap-common";

export const soapNoteHighlightedBarVerticalPaddingPx = 4;

export enum EncStatus {
  ML_ERROR = "ML_ERROR",
  ML_SUCCESS = "ML_SUCCESS",
  ML_STARTED = "ML_STARTED",

  ASR_ERROR = "ASR_ERROR",
  ASR_COMPLETED = "ASR_COMPLETED",
  ASR_STARTED = "ASR_STARTED",

  UPLOAD_ERROR = "UPLOAD_ERROR",
  UPLOAD_COMPLETED = "UPLOAD_COMPLETED",
  UPLOAD_STARTED = "UPLOAD_STARTED",

  RECORDING_COMPLETED = "RECORDING_COMPLETED",
  RECORDING_IN_PROGRESS = "RECORDING_IN_PROGRESS",
}

export const NO_TOPIC = "NO_TOPIC";

export const plainTextNewLine = "\r\n";

export const ENCOUNTER_DOC_KEYS = [
  "id",
  "subject",
  "appointment",
  "asrStatus",
  "asrStartedOn",
  "asrCompletedOn",
  "callInitiated",
  "description",
  "conversationStart",
  "conversationEnd",
  "conversationStatus",
  "levelOfServiceStatus",
  "duration",
  "provider",
  "deleted",
  "providerOrg",
  "reviewStatus",
  "chiefComplaint",
  "editorConfig",
  "mlStatus",
  "mlConfig.includeIcd10Codes",
  "mlConfig.noteSectionConfig",
  "icd10CodeStatus",
  "recordingUploadStatus",
  "recordingSource",
  "activeRecordingState",
  "soapNoteStatus",
  "pvsSendToEhr",
  "soapNoteSectionStatus",
];

export const SECTION_NOT_GENERATED_STATUSES = [
  MLGenerationStatus.GENERATION_ERROR,
];
