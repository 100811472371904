import { withLDProvider } from "launchdarkly-react-client-sdk";
import React, { ReactNode } from "react";

interface LDProviderProps {
  children?: ReactNode;
}

function LDProviderComponent({
  children,
}: LDProviderProps): React.ReactElement {
  return <>{children}</>;
}

const LaunchDarklyProvider = withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? "",
  options: {
    bootstrap: "localStorage",
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(LDProviderComponent as React.ComponentType);

export default LaunchDarklyProvider;
