import UserContext from "@contexts/UserContext";
import { sentryError } from "@integrations/sentry";
import { canUseDOM } from "@utils/common";
import React, { useContext } from "react";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FrontChat: any;
  }
}

export const FrontChatAPI = (...args: unknown[]): void => {
  if (canUseDOM() && window?.FrontChat) {
    window?.FrontChat?.apply(null, args);
  }
};

export const openFrontChat = (): void => {
  try {
    FrontChatAPI("show");
    revealFrontChatWidget();
  } catch (err) {
    sentryError(err);
  }
};

export const hideFrontChatWidget = (): void => {
  try {
    const chatContainer = document.getElementById("front-chat-iframe");
    if (chatContainer) {
      chatContainer.style.visibility = "hidden";
    }
  } catch (err) {
    sentryError(err);
  }
};

export const revealFrontChatWidget = (): void => {
  try {
    const chatContainer = document.getElementById("front-chat-iframe");
    if (chatContainer) {
      chatContainer.style.visibility = "visible";
    }
  } catch (err) {
    sentryError(err);
  }
};

export const FrontChatInit = (
  org?: string,
  name?: string,
  email?: string,
): void => {
  try {
    if (process.env.NEXT_PUBLIC_FRONT_CHAT_KEY) {
      FrontChatAPI("init", {
        chatId:
          org === "ukhs"
            ? process.env.NEXT_PUBLIC_FRONT_CHAT_UKHS_KEY
            : process.env.NEXT_PUBLIC_FRONT_CHAT_KEY,
        useDefaultLauncher: false,
      });
      if (name || email) {
        setTimeout(() => {
          FrontChatAPI("identity", {
            ...(name && { name }),
            ...(email && { email }),
          });
        }, 5000);
        document
          ?.getElementById("front-chat-container")
          ?.addEventListener("click", function handler(e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            e?.currentTarget?.removeEventListener(e.type, handler);
            FrontChatAPI("identity", {
              ...(name && { name }),
              ...(email && { email }),
            });
          });
      }
      hideFrontChatWidget(); // hidden by default; will be shown when they click on "Help" in the menu.
    }
  } catch (err) {
    sentryError(err);
  }
};

interface IFrontChatWidgetProps {
  name?: string;
  email?: string;
}

const FrontChatWidget: React.FC<IFrontChatWidgetProps> = () => {
  const { user } = useContext(UserContext);
  const onScriptLoaded = React.useCallback(() => {
    FrontChatInit(user?.org, user?.displayName, user?.email);
  }, [user?.org, user?.displayName, user?.email]);

  const insertScript = React.useCallback(() => {
    try {
      const script = document.createElement("script");
      script.id = "front-chat-snippet";
      script.src = `https://chat-assets.frontapp.com/v1/chat.bundle.js`;
      script?.addEventListener("load", () => onScriptLoaded());
      document?.body?.appendChild(script);
    } catch (err) {
      sentryError(err);
    }
  }, [onScriptLoaded]);

  React.useEffect(() => {
    return () => {
      FrontChatAPI("shutdown");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    try {
      if (!user?.identity_verified) return;
      if (!user?.displayName && !user?.email) return;
      if (
        user?.id &&
        user?.org &&
        process.env.NEXT_PUBLIC_DEPLOY_ENV === "production" &&
        process.env.NEXT_PUBLIC_FRONT_CHAT_KEY &&
        canUseDOM()
      ) {
        if (!window?.FrontChat) {
          try {
            // Only insert in production.
            insertScript();
          } catch (err) {
            sentryError(err);
          }
        } else {
          onScriptLoaded();
        }
      }
      FrontChatAPI("identity", {
        ...(user?.displayName && { name: user?.displayName }),
        ...(user?.email && { email: user?.email }),
        ...(user?.org && {
          customFields: {
            org: user?.org,
            uid: user?.id,
          },
        }),
      });
      hideFrontChatWidget();
    } catch (err) {
      sentryError(err);
    }
  }, [
    insertScript,
    onScriptLoaded,
    user?.displayName,
    user?.email,
    user?.id,
    user?.identity_verified,
    user?.org,
  ]);

  return null;
};

export default FrontChatWidget;
