import { getAuth } from "@firebase/auth";
import { sentryError } from "@integrations/sentry";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const myAxios = axios.create();

export default myAxios;

export const getRoute = async <T = any>(
  route: string,
): Promise<AxiosResponse<T>> => {
  const options: AxiosRequestConfig = {
    headers: !isInCypress() ? await getAuthHeaders() : {},
  };

  return axios.get<T>(route, options);
};

const getAuthHeaders = async (): Promise<AxiosRequestConfig["headers"]> => {
  try {
    const token = await getAuth()?.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Failed to get auth token from firebase user");
    }
    return { authorization: token };
  } catch (e) {
    sentryError(e);
    throw e;
  }
};

export const isInCypress = () => {
  if (process.env.NEXT_PUBLIC_DEPLOY_ENV === "production") {
    return false;
  }
  return typeof (window as any).Cypress !== "undefined";
};

export const postToRoute = async <T = any>(
  route: string,
  data: Record<string, any> = {},
  useAuthHeaders = true,
  controller: AbortController | null = null,
): Promise<AxiosResponse<T>> => {
  const options: AxiosRequestConfig = {
    headers: !isInCypress() && useAuthHeaders ? await getAuthHeaders() : {},
  };

  if (controller) {
    options.signal = controller.signal;
  }

  return axios.post<T>(route, data, options);
};

export const isAxiosError = axios.isAxiosError;
