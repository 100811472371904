import { Firestore_User_Account } from "@abridge/soap-common";
import { UserConfigurationsResponse } from "@services/functions";
import { AbridgeIndexedDB } from "@utils/idxDb";
import { exhaustiveCheck } from "@utils/enums";

export type WebRecordingUnsupportedStaticReason =
  | "unitialized"
  | "notEnabledForUser"
  | "noCryptography"
  | "noFileReader"
  | "notDesktopBrowser"
  | "duplicateTab"
  | "cannotOpenIndexedDb"
  | "noStorage";

/**
 * Describes the current storage availability level of the device.
 *
 * - `noStorage` - No storage, or not enough space to safely write to IndexedDB.
 * - `lowStorage` - Can store some data, but not safe to start or resume
 *   recordings.
 * - `sufficientStorage` - Enough storage to start or resume recordings.
 */
export type StorageQuotaLevel =
  | "noStorage"
  | "lowStorage"
  | "sufficientStorage";

export interface IWebRecordingSupport {
  readonly reason: WebRecordingUnsupportedStaticReason | null;
  readonly online: boolean;
  readonly storageQuotaLevel: StorageQuotaLevel;
  readonly sufficientStorage: boolean;
  readonly enabledForUser: boolean;
  readonly canAccessRecordings: boolean;
  readonly canStartNewRecording: boolean;
  readonly canResumeRecording: boolean;
  readonly longReasonMessage: string;
}

/**
 * Describes current state of support for recording encounters in the browser.
 *
 * Includes both static properties (user access, browser support) and dynamic
 * properties (online status, storage availability).
 */
export class WebRecordingSupport implements IWebRecordingSupport {
  /**
   * If there are any restrictions on recordings, the reason why.
   */
  readonly reason: WebRecordingUnsupportedStaticReason | null;

  readonly online: boolean;

  readonly storageQuotaLevel: StorageQuotaLevel;

  constructor({
    reason,
    online,
    storageQuotaLevel,
  }: {
    reason: WebRecordingUnsupportedStaticReason | null;
    online: boolean;
    storageQuotaLevel: StorageQuotaLevel;
  }) {
    this.reason = reason;
    this.online = online;
    this.storageQuotaLevel = storageQuotaLevel;
  }

  /**
   * Whether the user is allowed to use web recording at all (regardless of
   * whether it is technically feasible in this browser).
   */
  get enabledForUser(): boolean {
    switch (this.reason) {
      case "unitialized":
      case "notEnabledForUser":
        return false;
    }
    return true;
  }

  /**
   * Whether recordings are to be shown at all in the browser. This depends on
   * both user access and browser support.
   */
  get canAccessRecordings(): boolean {
    return this.reason === null;
  }

  /**
   * Whether a new recording can be started.
   */
  get canStartNewRecording(): boolean {
    return this.canAccessRecordings && this.online && this.sufficientStorage;
  }

  /**
   * Whether a paused recording can be resumed.
   */
  get canResumeRecording(): boolean {
    return this.canAccessRecordings && this.sufficientStorage;
  }

  /**
   * Whether there is enough storage to record audio.
   */
  get sufficientStorage(): boolean {
    return this.storageQuotaLevel === "sufficientStorage";
  }

  /**
   * Returns a sentence explaining why the user cannot record audio. This can
   * be shown in tooltips or other UI elements.
   */
  get longReasonMessage(): string {
    switch (this.reason) {
      case "unitialized":
      case "notEnabledForUser":
        // No message
        return "";
      case "noCryptography":
      case "noFileReader":
      case "notDesktopBrowser":
      case "cannotOpenIndexedDb":
      case "noStorage":
        return "This browser does not support recording audio.";
      case "duplicateTab":
        return "You can only record audio from one browser tab at a time.";
      case null:
        // Continue to temporary dynamic reasons
        break;
      default: {
        return exhaustiveCheck(this.reason);
      }
    }

    if (!this.sufficientStorage) {
      return "Not enough storage space on this device. Record on mobile or another desktop.";
    }

    if (!this.online) {
      return "Cannot start new recordings while offline.";
    }

    return "";
  }

  static defaultState(): WebRecordingSupport {
    return new WebRecordingSupport({
      reason: "unitialized",
      online: true,
      storageQuotaLevel: "sufficientStorage",
    });
  }

  static fullySupported(): WebRecordingSupport {
    return new WebRecordingSupport({
      reason: null,
      online: true,
      storageQuotaLevel: "sufficientStorage",
    });
  }
}

export type UseWebRecordingSupportArgs = {
  user: Pick<Firestore_User_Account, "id"> | undefined;
  userConfigurations: UserConfigurationsResponse | undefined;
  isDesktop: boolean;
  db: AbridgeIndexedDB | undefined;
};
