import { sentryBreadcrumb, sentryError } from "@integrations/sentry";
import { isClientSide } from "@utils/common";
import debounce from "lodash/debounce";
import amplitude from "amplitude-js"; // this cannot be destructured
import { isDevelopment } from "@constants";

/**
 * Add new amplitude events to this to ensure we don't name events incorrectly
 */
export type AmplitudeEventKey =
  | "ADMIN_CENTER_EDIT_USER_CLOSED"
  | "ADMIN_CENTER_EDIT_USER_SAVED"
  | "ADMIN_CENTER_EDIT_USER_CLICKED"
  | "ADMIN_CENTER_ENCOUNTER_AUDIO_PLAYED"
  | "ADMIN_CENTER_ENCOUNTER_NOTE_TYPE_CLICKED"
  | "ADMIN_CENTER_ENCOUNTER_NOTE_VARIANT_CLICKED"
  | "ADMIN_CENTER_ENCOUNTER_VIEWED"
  | "ADMIN_CENTER_USER_ENCOUNTERS_NEXT_PAGE_CLICKED"
  | "ADMIN_CENTER_USER_ENCOUNTERS_PREV_PAGE_CLICKED"
  | "ADMIN_CENTER_USER_ENCOUNTERS_SEARCHED"
  | "ADMIN_CENTER_USER_ENCOUNTERS_VIEWED"
  | "ADMIN_CENTER_USER_MANAGEMENT_NEXT_PAGE_CLICKED"
  | "ADMIN_CENTER_USER_MANAGEMENT_PREV_PAGE_CLICKED"
  | "ADMIN_CENTER_USER_MANAGEMENT_SEARCHED"
  | "ADMIN_CENTER_USER_MANAGEMENT_VIEWED"
  | "AFTER_VISIT_SUMMARY_COPIED"
  | "AFTER_VISIT_SUMMARY_CREATED"
  | "AFTER_VISIT_SUMMARY_EDITED"
  | "AFTER_VISIT_SUMMARY_PRINTED"
  | "PVS_SEND_SUMMARY_CLICKED"
  | "PVS_SEND_SUMMARY_MODAL_SHOWN"
  | "PVS_SEND_SUMMARY_MODAL_CONTINUED"
  | "PVS_SEND_SUMMARY_MODAL_CLOSED"
  | "NOTE_TAB_CLICKED"
  | "SOAP_DASHBOARD_LOAD"
  | "SOAP_DASHBOARD_LOGIN_EMAIL_ENTERED"
  | "SOAP_DASHBOARD_LOGIN_PHONE_ENTERED"
  | "SOAP_DASHBOARD_LOGIN_PHONE_OTP_ENTERED"
  | "SOAP_DASHBOARD_USER_UNVERIFIED"
  | "SOAP_DASHBOARD_USER_LOGIN_FAILED"
  | "SOAP_DASHBOARD_USER_LOGIN"
  | "SOAP_DASHBOARD_USER_LOGOUT"
  | "SOAP_DASHBOARD_LOAD_EDITOR"
  | "SOAP_DASHBOARD_LD_IDENTIFY_ERROR"
  | "SOAP_DASHBOARD_ENCOUNTER_LIST_TOGGLE"
  | "SOAP_DASHBOARD_ENCOUNTER_LIST_VIEW_ARCHIVE"
  | "SOAP_DASHBOARD_ENCOUNTER_LIST_VIEW_TO_REVIEW"
  | "SOAP_DASHBOARD_ENCOUNTER_LIST_PREV"
  | "SOAP_DASHBOARD_ENCOUNTER_LIST_NEXT"
  | "SOAP_DASHBOARD_RIGHT_PANE_TOGGLE"
  | "SOAP_DASHBOARD_NOTEWORTHY_VIEW"
  | "SOAP_DASHBOARD_NOTE_SETTINGS_CHANGED"
  | "SOAP_DASHBOARD_TRANSCRIPT_VIEW"
  | "SOAP_DASHBOARD_ENCOUNTER_VIEW"
  | "SOAP_DASHBOARD_SUMMARY_STATUS_UPDATE"
  | "SOAP_DASHBOARD_SUMMARY_SELECTED"
  | "SOAP_DASHBOARD_NOTE_SCROLL"
  | "SOAP_DASHBOARD_SUMMARY_UPDATED"
  | "SOAP_DASHBOARD_VERBATIM_UPDATED"
  | "SOAP_DASHBOARD_AUDIO_PLAY"
  | "SOAP_DASHBOARD_AUDIO_SEEK"
  | "SOAP_DASHBOARD_AUDIO_PAUSE"
  | "SOAP_DASHBOARD_AUDIO_PLAYBACK_SPEED_CHANGE"
  | "SOAP_DASHBOARD_MEDICAL_TERM_SELECTED"
  | "SOAP_DASHBOARD_MEDICAL_TERM_UNSELECTED"
  | "SOAP_DASHBOARD_TRANSCRIPT_VIEW"
  | "SOAP_DASHBOARD_NOTEWORTHY_SCROLL"
  | "SOAP_DASHBOARD_TRANSCRIPT_SCROLL"
  | "SOAP_DASHBOARD_NOTE_COMPLETED"
  | "SOAP_DASHBOARD_NOTE_COPY_ALL"
  | "SOAP_DASHBOARD_NOTE_COPY_SECTION"
  | "SOAP_DASHBOARD_NOTE_COPY_VERBATIM"
  | "SOAP_DASHBOARD_ENCOUNTER_FEEDBACK_SUBMITTED"
  | "SOAP_DASHBOARD_TOGGLE_TERM_SECTION"
  | "SOAP_DASHBOARD_NOTE_ARCHIVED"
  | "SOAP_DASHBOARD_LINKAGES_FETCHED"
  | "SOAP_DASHBOARD_LINKAGES_CARET_CLICK"
  | "SOAP_DASHBOARD_LINKAGES_CLOSE_CLICK"
  | "SOAP_DASHBOARD_LINKAGES_INFO_CLICK"
  | "SOAP_DASHBOARD_LINKAGES_FEEDBACK"
  | "SOAP_DASHBOARD_SUMMARY_TEXT_SELECTED"
  | "SOAP_DASHBOARD_ACCOUNT_SETTINGS_VIEW"
  | "SOAP_DASHBOARD_NOTE_SETTINGS_VIEW"
  | "SOAP_DASHBOARD_CODING_LINKAGES_INFO_CLICK"
  | "SOAP_DASHBOARD_CODING_LINKAGES_CARET_CLICK"
  | "SOAP_DASHBOARD_CODING_TAB_VIEW"
  | "SOAP_DASHBOARD_CODING_CODE_SELECTED"
  | "SOAP_DASHBOARD_CODING_TRANSCRIPT_LINKAGES_VIEW"
  | "SOAP_DASHBOARD_CODING_EDIT_CODE_VIEW"
  | "SOAP_DASHBOARD_CODING_EDIT_ALTERNATIVE_SELECTED"
  | "SOAP_DASHBOARD_CODING_EDIT_RETURN_TO_HOME"
  | "SOAP_DASHBOARD_CODING_EDIT_NAVIGATE"
  | "SOAP_DASHBOARD_CODING_CODE_CONFIRM_TOGGLE"
  | "SOAP_DASHBOARD_CODING_CONFIRM_CODES"
  | "SOAP_DASHBOARD_NOTE_PRONOUN_SELECTED"
  | "SOAP_DASHBOARD_NOTE_PRONOUN_EXCEPTION"
  | "SOAP_DASHBOARD_NOTE_PRONOUN_BLANK_UPDATE"
  | "SOAP_DASHBOARD_NOTE_PRONOUN_NOT_SIMILAR_UPDATE"
  | "SOAP_DASHBOARD_NOTE_PRONOUN_EDIT_DISTANCE"
  | "SOAP_CLINICIAN_ACCESS_CODE_REDEEM_FAILED"
  | "SOAP_CLINICIAN_ONBOARDING_WIZARD_NAVIGATE"
  | "SOAP_CLINICIAN_JOIN_WAITLIST_CLICKED"
  | "SOAP_DASHBOARD_REFERRAL_COPY_MESSAGE_CLICKED"
  | "SOAP_DASHBOARD_REFERRAL_INVITE_OTHERS_CTA_CLICKED"
  | "SOAP_DASHBOARD_ENCOUNTER_DESCRIPTOR_EDIT"
  | "SOAP_DASHBOARD_ENCOUNTER_DESCRIPTOR_ADD"
  | "SOAP_DASHBOARD_ENCOUNTER_LIST_DONE_FILTER_TOGGLE"
  | "SOAP_DASHBOARD_WR_DISABLED"
  | "SOAP_DASHBOARD_WR_NEW_RECORDINGS_ENABLED"
  | "SOAP_DASHBOARD_WR_NEW_RECORDINGS_DISABLED"
  | "SOAP_DASHBOARD_WR_SELECT_MICROPHONE"
  | "SOAP_DASHBOARD_WR_SELECT_TAB_OR_WINDOW"
  | "SOAP_DASHBOARD_WR_START_RECORDING"
  | "SOAP_DASHBOARD_WR_CHUNKS_STUCK_ENCRYPTING"
  | "SOAP_DASHBOARD_WR_PAUSE_RECORDING"
  | "SOAP_DASHBOARD_WR_RESUME_RECORDING"
  | "SOAP_DASHBOARD_WR_ONBOARDING_PERMISSIONS_ERROR"
  | "SOAP_DASHBOARD_WR_ONBOARDING_PERMISSIONS_GRANTED"
  | "SOAP_DASHBOARD_WR_DELETE_RECORDING"
  | "SOAP_DASHBOARD_WR_STOP_RECORDING"
  | "SOAP_DASHBOARD_WR_VIRTUAL_RECORDING_ENABLED"
  | "SOAP_DASHBOARD_WR_CHUNK_TOO_SMALL"
  | "SOAP_DASHBOARD_WR_CHUNK_UPLOAD_INITIATED"
  | "SOAP_DASHBOARD_WR_CHUNK_UPLOAD_FAILED"
  | "SOAP_DASHBOARD_WR_CHUNK_UPLOAD_SUCCESS"
  | "SOAP_DASHBOARD_WR_CHUNK_RETENTION_POLICY_ENFORCED"
  | "SOAP_DASHBOARD_WR_CHUNK_ENCRYPTION_SUCCESS"
  | "SOAP_DASHBOARD_WR_CHUNK_ENCRYPTION_FAILED"
  | "SOAP_DASHBOARD_WR_METADATA_WRITE_SUCCESS"
  | "SOAP_DASHBOARD_WR_METADATA_WRITE_INITIATED"
  | "SOAP_DASHBOARD_WR_METADATA_WRITE_FAILED"
  | "SOAP_DASHBOARD_WR_RETRYING_RECORDING_UPLOAD"
  | "SOAP_DASHBOARD_WR_PERSISTED_STORAGE_REQUESTED"
  | "SOAP_DASHBOARD_NETWORK_STATUS"
  | "SOAP_DASHBOARD_WR_METADATA_WAITING_FOR_UPLOAD"
  | "SOAP_DASHBOARD_WR_DELETE_ENCOUNTER_CHUNKS"
  | "SOAP_DASHBOARD_WR_VISIT_TYPE_CHANGED"
  | "SOAP_DASHBOARD_WR_SOUND_CHECK_PASSED"
  | "SOAP_DASHBOARD_WR_PUBLIC_KEY_NOT_FOUND"
  | "SOAP_DASHBOARD_WR_INITIALIZE_RECORDINGS"
  | "SOAP_DASHBOARD_WR_RECORDING_FROM_ANOTHER_BROWSER"
  | "SOAP_DASHBOARD_WR_ADD_RECORDING_CLICKED"
  | "SOAP_DASHBOARD_CHANGE_THEME"
  | "SOAP_DASHBOARD_ICD10_CUSTOM_CODE_INPUT_FOCUS"
  | "SOAP_DASHBOARD_ENCOUNTER_AUDIO_LOADING_FAILED";

type WebRecorderAmplitudeEventKey = Extract<
  AmplitudeEventKey,
  `SOAP_DASHBOARD_WR_${string}`
>;

/**
 * Type guard for {@link WebRecorderAmplitudeEventKey}.
 */
const isWebRecorderAmplitudeEventKey = (
  eventName: AmplitudeEventKey,
): eventName is WebRecorderAmplitudeEventKey => {
  return eventName.startsWith("SOAP_DASHBOARD_WR_");
};

export const initAmplitude = (): void => {
  try {
    if (!isClientSide()) return;
    const config: amplitude.Config = {
      includeUtm: true,
      includeReferrer: true,
      includeGclid: true,
      trackingOptions: { ip_address: false },
    };
    if (process.env.NEXT_PUBLIC_AMPLITUDE_API_ENDPOINT) {
      const apiEndpoint = process.env.NEXT_PUBLIC_AMPLITUDE_API_ENDPOINT;
      console.log("setting amplitude endpoint:", apiEndpoint);
      config.apiEndpoint = apiEndpoint;
    } else {
      console.warn(
        "no amplitude api endpoint env var found. using default amplitude endpoint",
      );
    }
    if (!process.env.NEXT_PUBLIC_AMPLITUDE_CLIENT_KEY) {
      console.log("amplitude not initialized - no client key");
      return;
    }
    amplitude
      ?.getInstance()
      ?.init(process.env.NEXT_PUBLIC_AMPLITUDE_CLIENT_KEY, undefined, config);
    console.log("amplitude initialized");
    try {
      logAmplitude("SOAP_DASHBOARD_LOAD");
    } catch (err) {
      console.error("failed to send SOAP_DASHBOARD_LOAD event");
    }
  } catch (err) {
    console.error("failed to initialize amplitude", err);
    sentryError(err);
  }
};

export const setAmplitudeUser = (
  uid: string | null = null,
  userProperties?: Record<string, any>,
): void => {
  try {
    if (!isClientSide()) return;
    if (!process.env.NEXT_PUBLIC_AMPLITUDE_CLIENT_KEY) {
      console.log("amplitude not initialized - no client key");
      return;
    }
    amplitude?.getInstance?.()?.setUserId?.(uid);
    if (userProperties) {
      amplitude?.getInstance()?.setUserProperties?.(userProperties);
    }
  } catch (err) {
    console.error("failed to set amplitude user", err);
    sentryError(err);
  }
};

export type LogAmplitude = (
  /**
   * The name/key of the event to send to amplitude
   */
  eventName: AmplitudeEventKey,
  /**
   * JSON object representing the properties to add to the event in amplitude
   */
  eventProperties?: Record<string, any>,
) => void;

export type LogAmplitudeForEvent = (
  /**
   * JSON object representing the properties to add to the event in amplitude
   */
  eventProperties?: Record<string, any>,
) => void;

export const logAmplitude: LogAmplitude = (eventName, eventProperties = {}) => {
  try {
    if (!isClientSide()) return;
    if (isDevelopment) {
      console.log("amplitude event :: ", eventName, eventProperties);
    }
    if (!process.env.NEXT_PUBLIC_AMPLITUDE_CLIENT_KEY) {
      console.log("amplitude not initialized - no client key");
      return;
    }
    if (eventName && process.env.NEXT_PUBLIC_AMPLITUDE_CLIENT_KEY) {
      amplitude?.getInstance?.()?.logEvent(eventName, eventProperties);
    }
  } catch (err) {
    sentryError(err);
  } finally {
    if (isWebRecorderAmplitudeEventKey(eventName)) {
      sentryBreadcrumb(`Amplitude Event: ${eventName}`, eventProperties);
    }
  }
};

export type AmplitudeUserProperties = {
  org?: string;
  role?: string;
  kp_region?: string;
  storage?: {
    estimate?: StorageEstimate;
    lowStorageThresholdBytes?: number;
    persisted?: boolean;
  };
};

export const setAmplitudeUserProperties = (
  properties?: AmplitudeUserProperties,
): void => {
  try {
    if (!isClientSide()) return;
    if (isDevelopment) {
      console.log("amplitude user properties :: ", properties);
    }
    if (!process.env.NEXT_PUBLIC_AMPLITUDE_CLIENT_KEY) {
      console.log("amplitude not initialized - no client key");
      return;
    }
    if (properties) {
      amplitude?.getInstance?.().setUserProperties?.(properties);
    }
  } catch (err) {
    console.error("failed to log amplitude event", err);
    sentryError(err);
  }
};

export interface GetDebouncedLogAmplitudeForEventOpts {
  event: AmplitudeEventKey;
  ms?: number;
  logAmplitudeFn?: LogAmplitude;
}

export type GetDebouncedLogAmplitudeForEvent = (
  opts: GetDebouncedLogAmplitudeForEventOpts,
) => LogAmplitudeForEvent;

export const getDebouncedLogAmplitudeForEvent: GetDebouncedLogAmplitudeForEvent =
  ({
    event,
    logAmplitudeFn = logAmplitude,
    ms = 200,
  }): LogAmplitudeForEvent => {
    const logAmplitudeForEventFn: LogAmplitudeForEvent = (
      eventProperties = {},
    ) => {
      logAmplitudeFn(event, eventProperties);
    };
    return debounce(logAmplitudeForEventFn, ms);
  };
