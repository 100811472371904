import { AbridgeIndexedDB } from "@utils/idxDb";
import {
  UseWebRecordingSupportArgs,
  WebRecordingSupport,
  WebRecordingUnsupportedStaticReason,
} from "./types";
import { sentryMessage } from "@integrations/sentry";
import { isDuplicateTab } from "@utils/multitab";
import { checkStorageQuota } from "./storage";
import {
  logAmplitude,
  setAmplitudeUserProperties,
} from "@integrations/amplitude";
import { exhaustiveCheck } from "@utils/enums";

const MiB = 1024 * 1024;

export type CheckStaticSupportResult = {
  reason: WebRecordingUnsupportedStaticReason | null;
  storage?: {
    estimate: StorageEstimate;
    lowStorageThresholdBytes: number;
  };
};

export const checkStaticSupport = async ({
  user,
  webRecordingFeatureFlag,
  minRecordingStorageMib,
  userConfigurations,
  isDesktop,
  db,
}: UseWebRecordingSupportArgs & {
  webRecordingFeatureFlag: boolean;
  minRecordingStorageMib: number;
}): Promise<CheckStaticSupportResult> => {
  if (!user?.id || !userConfigurations) {
    return { reason: "unitialized" };
  }

  // Check feature flag
  if (
    !webRecordingFeatureFlag &&
    !userConfigurations.editorConfig?.enableWebRecording
  ) {
    return { reason: "notEnabledForUser" };
  }

  // Check crypto library support
  if (!window?.crypto?.subtle) {
    return { reason: "noCryptography" };
  }

  // Check FileReader support
  if (!window.FileReader) {
    return { reason: "noFileReader" };
  }

  // Check if desktop
  if (!isDesktop) {
    return { reason: "notDesktopBrowser" };
  }

  // Check if duplicate tab
  if (isDuplicateTab()) {
    return { reason: "duplicateTab" };
  }

  if (!(await canOpenIndexedDb(db))) {
    return { reason: "cannotOpenIndexedDb" };
  }

  const lowStorageThresholdBytes = minRecordingStorageMib * MiB;

  const { level: storageLevel, estimate: storageEstimate } =
    await checkStorageQuota(lowStorageThresholdBytes);

  const storage = storageEstimate
    ? { estimate: storageEstimate, lowStorageThresholdBytes }
    : undefined;

  if (storageLevel === "noStorage") {
    return {
      reason: storageLevel,
      ...(storage && { storage }),
    };
  }

  return { reason: null, ...(storage && { storage }) };
};

/**
 * Log the support check result to Sentry and/or Amplitude.
 */
export const logSupport = ({
  reason,
  storage,
}: CheckStaticSupportResult): void => {
  // Sentry
  switch (reason) {
    case "unitialized":
    case "notEnabledForUser":
      // No logging
      break;
    case "noCryptography":
      sentryMessage("Web Crypto API not found; Web recording disabled");
      break;
    case "noFileReader":
      sentryMessage("FileReader API not found; Web recording disabled");
      break;
    case "notDesktopBrowser":
      sentryMessage("Not a desktop browser; Web recording disabled");
      break;
    case "duplicateTab":
      break;
    case "cannotOpenIndexedDb":
      sentryMessage("Unable to open indexeddb; Web recording disabled");
      break;
    case "noStorage":
      sentryMessage("Storage unavailable; Web recording disabled");
      break;
    case null:
      break;
    default: {
      return exhaustiveCheck(reason);
    }
  }

  // Amplitude
  if (storage) {
    // These values may later be overwritten in `usePersistence()`.
    setAmplitudeUserProperties({ storage });
  }
  if (reason && reason !== "unitialized") {
    logAmplitude("SOAP_DASHBOARD_WR_DISABLED", { reason, storage });
  }
};

const canOpenIndexedDb = async (
  db: AbridgeIndexedDB | undefined,
): Promise<boolean> => {
  try {
    if (!db?.isOpen()) {
      await db?.openDb?.();
    }
    return !!db?.isOpen();
  } catch (e) {
    return false;
  }
};

/**
 * Log the ability to start a new recording to Amplitude. This should be called
 * whenever `WebRecordingSupport.canStartNewRecording` changes.
 */
export const logCanStartNewRecording = async (
  webRecordingSupport: WebRecordingSupport,
): Promise<void> => {
  const { reason, online, storageQuotaLevel, canStartNewRecording } =
    webRecordingSupport;
  if (reason === "unitialized") {
    return;
  }
  const event = canStartNewRecording
    ? "SOAP_DASHBOARD_WR_NEW_RECORDINGS_ENABLED"
    : "SOAP_DASHBOARD_WR_NEW_RECORDINGS_DISABLED";

  let storageEstimate: StorageEstimate | undefined;
  try {
    // The values might have changed slightly since the event that triggered
    // the call to `logCanStartNewRecording`, but should still provide useful
    // debugging information.
    storageEstimate = await navigator.storage.estimate();
  } catch (e) {
    // Ignore
  }

  const storage = storageEstimate ? { estimate: storageEstimate } : undefined;

  logAmplitude(event, {
    ...(reason && { reason }),
    online,
    storageQuotaLevel,
    ...(storage && { storage }),
  });
};
