/* eslint-disable no-empty */
import { isDevelopment } from "@constants";
import * as Sentry from "@sentry/nextjs";
import { CaptureContext } from "@sentry/types/types/scope";

interface SetSentryOrgOptions {
  id: string;
  kp_region?: string;
}

export const setSentryUser = (uid = ""): void => {
  try {
    if (isDevelopment) {
      console.info("Sentry User Set :: ", uid);
    }
    if (isDevelopment || !process.env.NEXT_PUBLIC_SENTRY_DSN || !uid) return;
    Sentry.setUser({
      id: uid,
    });
  } catch (err) {}
};

export const setSentryOrg = (options: SetSentryOrgOptions): void => {
  try {
    if (isDevelopment) {
      console.info("Sentry Org Set :: ", options.id);
    }
    if (isDevelopment || !process.env.NEXT_PUBLIC_SENTRY_DSN) return;
    Sentry.setTags({
      "org.id": options.id,
      "org.kp_region": options.kp_region,
    });
  } catch (err) {}
};

export const sentryError = (error: unknown, context?: CaptureContext): void => {
  try {
    if (isDevelopment) {
      console.error("Sentry Error :: ", error);
    }
    if (isDevelopment || !process.env.NEXT_PUBLIC_SENTRY_DSN) return;
    if (error) {
      Sentry.captureException(error, context);
    }
  } catch (e) {}
};

export const sentryMessage = (message = "", context?: CaptureContext): void => {
  try {
    if (isDevelopment) {
      console.info("Sentry Message :: ", message);
    }
    if (isDevelopment || !process.env.NEXT_PUBLIC_SENTRY_DSN) return;
    if (message) {
      Sentry.captureMessage(message, context);
    }
  } catch (e) {}
};

export const sentryBreadcrumb = (
  message = "",
  context?: CaptureContext,
): void => {
  try {
    if (isDevelopment) {
      console.info("Sentry Breadcrumb :: ", message);
    }
    if (isDevelopment || !process.env.NEXT_PUBLIC_SENTRY_DSN) return;
    if (message) {
      Sentry.addBreadcrumb({
        message,
        ...context,
      });
    }
  } catch (e) {}
};
