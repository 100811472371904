/**
 * This function is used to check if all cases of an enum or union type are
 * handled in a switch statement, both at compile time and at runtime.
 *
 * Usage:
 * ```typescript
 *
 * enum MyEnum {
 *   A,
 *   B,
 *   C,
 * }
 *
 * function doSomething(value: MyEnum): void {
 *   switch (value) {
 *    case MyEnum.A:
 *      // do A
 *      break;
 *    case MyEnum.B:
 *      // do B
 *      break;
 *    default:
 *      return exhaustiveCheck(value);
 *      // TS2345: Argument of type 'MyEnum' is not assignable to parameter of
 *      // type 'never'.
 *   }
 * }
 *
 * ```
 *
 * @param value Switch statement value.
 */
export const exhaustiveCheck = (value: never): never => {
  throw new Error(`Unhandled case: ${value}`);
};
