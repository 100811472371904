import AbridgeToastContainer from "@components/AbridgeToast";
import ErrorBoundary from "@components/ErrorBoundary";
import { metaKeywords } from "@constants";
import { UserContextProvider } from "@contexts/UserContext";
import { initAmplitude } from "@integrations/amplitude";
import { initBraze } from "@integrations/braze";
import { disableDevToolsInProduction, isDetectifyBot } from "@utils/common";
import initAuth from "@utils/initAuth";
import { triggerTabCheck } from "@utils/multitab";
import { NextPage } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import { ReactElement, ReactNode, useEffect } from "react";

// Global style imports
import "@components/OTPInput/OTPInput.scss";
import "@styles/abridge-toast.scss";
import "@styles/datepicker-overrides.scss";
import "@styles/global.scss";
import "@styles/theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import LaunchDarklyProvider from "@components/LaunchDarklyProvider";
import { ThemeContextProvider } from "@contexts/ThemeContext";

initAuth();

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout): JSX.Element {
  useEffect(() => {
    if (!isDetectifyBot()) {
      initAmplitude();
      initBraze();
    }
    triggerTabCheck();
    disableDevToolsInProduction();
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <title>{`${
          process.env.NEXT_PUBLIC_DEPLOY_ENV !== "production"
            ? `${(
                process.env.NEXT_PUBLIC_DEPLOY_ENV || ""
              )?.toUpperCase?.()} | `
            : ""
        }Abridge Clinician | Editor`}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="keywords" content={metaKeywords} />
        <meta
          name="description"
          content="Go from dialogue to structured clinical document, simply by recording your patient visits. Completing your SOAP note has never been easier."
        />
        <link rel="shortcut icon" href="icons/favicon.ico" />
      </Head>
      <ThemeContextProvider>
        <ErrorBoundary>
          <LaunchDarklyProvider>
            <UserContextProvider>
              {getLayout(<Component {...pageProps} />)}
            </UserContextProvider>
          </LaunchDarklyProvider>
          <AbridgeToastContainer />
        </ErrorBoundary>
      </ThemeContextProvider>
    </>
  );
}

export default MyApp;
