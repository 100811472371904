import { MLOutput_SoapSectionKey } from "@abridge/soap-common";

export enum NoteOrgSettingType {
  ONLY_SECTIONS = "ONLY_SECTIONS",
}
export interface NoteOrgSetting {
  type: NoteOrgSettingType;
  sections: MLOutput_SoapSectionKey[];
}

export const NOTE_ORG_SETTINGS: Record<string, NoteOrgSetting[]> = {
  carbon: [
    {
      type: NoteOrgSettingType.ONLY_SECTIONS,
      sections: [
        MLOutput_SoapSectionKey.ASSESSMENT,
        MLOutput_SoapSectionKey.DIAGNOSTICS_AND_APPOINTMENTS,
        MLOutput_SoapSectionKey.PRESCRIPTIONS_AND_THERAPEUTICS,
        MLOutput_SoapSectionKey.HISTORY_OF_PRESENT_ILLNESS,
      ],
    },
  ],
};
