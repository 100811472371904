import { sentryError } from "@integrations/sentry";
import { isClientSide } from "@utils/common";
import { local } from "@utils/storage";
import React, {
  createContext,
  useCallback,
  useLayoutEffect,
  useState,
} from "react";
import { flushSync } from "react-dom";

export type DashboardTheme = "light" | "dark";

export type ThemeContextType = {
  theme: DashboardTheme;
  changeTheme: (theme: DashboardTheme) => void;
};

const ThemeContext = createContext<ThemeContextType>({
  theme: "light",
  changeTheme: () => console.warn("changeTheme is not initialized."),
});

interface IThemeContextProviderProps {
  matchSystem?: boolean;
}

export const ThemeContextProvider: React.FC<IThemeContextProviderProps> = ({
  matchSystem = false,
  children,
}) => {
  const defaultDark = isClientSide()
    ? window.matchMedia("(prefers-color-scheme: dark)").matches
    : false;
  const localConfig = local?.getItem?.("abr-theme");
  const [theme, setTheme] = useState<DashboardTheme>(
    (matchSystem && defaultDark) || localConfig === "dark" ? "dark" : "light",
  ); // light mode by default unless manually set by the user.

  const changeTheme = useCallback((theme: DashboardTheme): void => {
    setTheme(theme);
    local?.setItem?.("abr-theme", theme);
  }, []);

  useLayoutEffect(() => {
    const applyTheme = async () => {
      const d = document.documentElement;
      if (d) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!document?.startViewTransition) {
          d?.setAttribute?.("data-theme", theme);
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          await document?.startViewTransition?.(() => {
            flushSync(() => {
              d?.setAttribute?.("data-theme", theme);
            });
          }).ready;

          try {
            if (theme !== "dark") return;
            const { top, left } = d.getBoundingClientRect();
            const x = left;
            const y = top;
            const right = window.innerWidth - left;
            const bottom = window.innerHeight - top;
            // Calculates the radius of circle that can cover the screen
            const maxRadius = Math.hypot(
              Math.max(left, right),
              Math.max(top, bottom),
            );

            document.documentElement.animate(
              {
                clipPath: [
                  `circle(0px at ${x}px ${y}px)`,
                  `circle(${maxRadius}px at ${x}px ${y}px)`,
                ],
              },
              {
                duration: 500,
                easing: "ease-in-out",
                pseudoElement: "::view-transition-new(root)",
              },
            );
          } catch (error) {
            sentryError(error);
          }
        }
      }
    };

    applyTheme();
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        changeTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
