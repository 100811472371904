import { logAmplitude } from "@integrations/amplitude";
import { sentryMessage } from "@integrations/sentry";
import { AbridgeIndexedDB, db, reportDexieError } from "@utils/idxDb";

const createDiagnosticReportObject = async () => {
  const chunkCount = await db.recordingChunks.count();
  const recordingsCount = await db.recordings.count();

  return { chunkCount, recordingsCount };
};

export const warnSentryForNullDocument = async <T>(recChunk: T | null) => {
  sentryMessage("WebRecorder -> document from IndexedDb is null", {
    extra: {
      recChunk,
      ...(await createDiagnosticReportObject()),
    },
  });
};

/**
 * Deletes all IndexDB records related to a specific encounter.
 * Use cautiously; this will delete pending uploads as well.
 */
export const deleteEncounterIdxDbRecord = async (
  encounterId: string,
  db: AbridgeIndexedDB,
): Promise<void> => {
  logAmplitude("SOAP_DASHBOARD_WR_DELETE_ENCOUNTER_CHUNKS", {
    encounterId,
  });

  try {
    await db.transaction(
      "readwrite",
      db.recordings,
      db.recordingChunks,
      async () => {
        const allChunks = await db.recordingChunks.filter(
          (recordingChunk) => recordingChunk?.encounterId === encounterId,
        );

        await allChunks.delete();
        await db.recordings.delete(encounterId);
      },
    );
  } catch (err) {
    reportDexieError(err);
  }
};
