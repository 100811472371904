import { RsaKey } from "@dto/encounters";
import crypto from "crypto";

/**
 * Generates a new key pair to be used for encrypting audio chunks recorded by the web recorder.
 */
export const generateKeyPair = (): Promise<{
  publicKey: RsaKey;
  privateKey: RsaKey;
}> =>
  new Promise((resolve, reject) => {
    crypto.generateKeyPair(
      "rsa",
      {
        modulusLength: 2048,
        publicKeyEncoding: {
          type: "spki",
          format: "pem",
        },
        privateKeyEncoding: {
          type: "pkcs8",
          format: "pem",
        },
      },
      (error, newPublicKey, newPrivateKey) => {
        if (error) {
          return reject(error);
        }

        if (!newPublicKey || !newPrivateKey) {
          throw new Error("New keys not generated");
        }

        const publicKey = RsaKey.parse(
          Buffer.from(newPublicKey).toString("base64"),
        );
        const privateKey = RsaKey.parse(
          Buffer.from(newPrivateKey).toString("base64"),
        );

        return resolve({
          publicKey,
          privateKey,
        });
      },
    );
  });
