import { warnSentryForNullDocument } from "@contexts/recording/persistence/util";
import { sentryError } from "@integrations/sentry";
import { arrayBufferToBase64 } from "@utils/crypt";
import { DexieError } from "dexie";
import { noop } from "lodash";
import { AbridgeIndexedDB } from "./db";
import { Recording, RecordingChunk, RecordingUploadStatus } from "./types";

export interface SanitizedRecording extends Omit<Recording, "pubKey"> {
  pubKeyHash?: string;
}

export interface SanitizedRecordingChunks
  extends Omit<
    RecordingChunk,
    "chunkData" | "chunkDataBuffer" | "encKey" | "encSign"
  > {
  chunkDataSize: number | undefined;
}

/**
 * Sanitizes recording chunk data for reporting/debugger purposes by omitting audio and encryption data.
 */
export const sanitizeRecording = async ({
  encounterId,
  recordingStatus,
  uploadStatus,
  recordingTimestamp,
  startTime,
  tabId,
  browserId,
  pubKey,
  retryCount,
  endTime,
  createdOn,
  updatedOn,
  uploaderVersion,
}: Recording): Promise<SanitizedRecording> => {
  let pubKeyHash;

  // All recordings should have a pubKey, however there are a small instances that do not which need to be investigated.
  // Sentry: https://abridge-ai.sentry.io/share/issue/1cbac0df108b4afb8854908bc2895e43/
  if (pubKey) {
    pubKeyHash = arrayBufferToBase64(
      await window.crypto.subtle.digest("SHA-256", Buffer.from(pubKey)),
    );
  }

  return {
    encounterId,
    recordingStatus,
    uploadStatus,
    recordingTimestamp,
    startTime,
    tabId,
    browserId,
    pubKeyHash,
    retryCount,
    endTime,
    createdOn,
    updatedOn,
    uploaderVersion,
  };
};

/**
 * Sanitizes recording chunk data for reporting/debugger purposes by omitting audio and encryption data.
 */
export const sanitizeRecordingChunk = ({
  userId,
  encounterId,
  chunkId,
  chunkSeq,
  mimeType,
  chunkData,
  chunkDataBuffer,
  uploadStatus,
  retryCount,
  isLastChunk,
  createdOn,
  updatedOn,
}: RecordingChunk): SanitizedRecordingChunks => ({
  userId,
  encounterId,
  chunkId,
  chunkSeq,
  mimeType,
  chunkDataSize: chunkDataBuffer?.byteLength || chunkData?.length,
  uploadStatus,
  retryCount,
  isLastChunk,
  createdOn,
  updatedOn,
});

/**
 * According to the Dexie types, records should never be nullable. However we've seen multiple instances
 * of users having corrupt data that causes type errors during runtime.
 */
export const filterNullDocuments = <T>(document: T | null): boolean => {
  if (!document) {
    warnSentryForNullDocument(document).catch(noop);
    return false;
  }
  return true;
};

export type RecordingChunkSummary = Record<
  RecordingUploadStatus | "null",
  number
>;

export interface RecordingChunkAnalytics {
  sanitizedRecordingChunks?: SanitizedRecordingChunks[];
  recordingChunkSummary?: RecordingChunkSummary;
}

/**
 * Safely fetches recording chunks from indexDB and counts the number of chunks per `uploadStatus`.
 */
export const getRecordingChunkAnalytics = async (
  encounterId: string,
  db: AbridgeIndexedDB,
): Promise<RecordingChunkAnalytics> => {
  try {
    const recordingChunks: Array<RecordingChunk | null> =
      await db.recordingChunks
        .filter((recChunk) => recChunk?.encounterId === encounterId)
        ?.toArray();

    const recordingChunkSummary = recordingChunks.reduce(
      (acc, recordingChunk) => {
        const status = recordingChunk?.uploadStatus ?? "null";

        if (acc[status] === undefined) {
          acc[status] = 1;
        } else {
          acc[status]++;
        }

        return acc;
      },
      {} as RecordingChunkSummary,
    );

    const filtered = recordingChunks.filter(
      filterNullDocuments,
    ) as RecordingChunk[];

    return {
      recordingChunkSummary,
      sanitizedRecordingChunks: filtered.map(sanitizeRecordingChunk),
    };
  } catch (error) {
    sentryError(error);
    return {} as RecordingChunkAnalytics;
  }
};

/**
 * Type guard for errors emitted by Dexie.js
 */
export const isDexieError = (error: unknown): error is DexieError => {
  return !!error && typeof error === "object" && "inner" in error;
};

/**
 * Reports error to Sentry. Includes underlying error details from Dexie error if found.
 */
export const reportDexieError = (error: unknown): void => {
  const context = isDexieError(error)
    ? {
        extra: {
          inner: error.inner,
          message: error.message,
        },
      }
    : undefined;

  sentryError(error, context);
};
