var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"develop-3695"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const traceSampleRate =
  process.env.NEXT_PUBLIC_DEPLOY_ENV === "production" ? 0.15 : 0;

Sentry.init({
  dsn: SENTRY_DSN || "",
  ...(process.env.NEXT_PUBLIC_DEPLOY_ENV && {
    environment: process.env.NEXT_PUBLIC_DEPLOY_ENV,
  }),
  ignoreErrors: [
    // https://abridge-ai.sentry.io/issues/3067241190/?project=5969623
    "FirebaseError: Missing or insufficient permissions.",
    // https://abridge-ai.sentry.io/issues/5441839121/?project=5969623
    "InvalidStateError: Transition was aborted because of invalid state",
    // https://abridge-ai.sentry.io/issues/4243090137/?project=5969623
    "FirebaseError: Firebase: Error (auth/tenant-id-mismatch)",
  ],
  tracesSampler: (samplingContext) => {
    // Prevent traces for high volume endpoints
    if (
      samplingContext.name.includes("wavelength") ||
      samplingContext.name.includes("api/health") ||
      samplingContext.name.includes("api/alert") ||
      samplingContext.name.includes("api/recording/upload-state")
    ) {
      return 0;
    }

    return traceSampleRate;
  },
  beforeSend: (event) => {
    const headers = event?.request?.headers;
    if (headers && headers?.["User-Agent"]?.indexOf?.("detectify.com") > -1) {
      //returning null will drop the event, nothing will be sent to Sentry
      return null;
    }
    return event;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
