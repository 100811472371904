import {
  FirestoreCollection,
  Firestore_Soap_Conversation,
  Firestore_Soap_Encounter,
  Firestore_User_Account,
  UserProfile,
} from "@abridge/soap-common";
import { sentryError } from "@integrations/sentry";
import { SimpleObject, UserEventWithTimestamp } from "@types_";
import {
  DocumentSnapshot,
  FirestoreError,
  QuerySnapshot,
  Unsubscribe,
  arrayUnion,
  collection,
  doc,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { cloneDeep } from "lodash";
import { DateTime } from "luxon";
import { postToRoute } from "./axios";
import { ErrorResponse } from "@dto";
import { AxiosResponse } from "axios";

/**
 * @deprecated
 * use `updateDocumentAttributes` instead
 */
const setDocumentAttributes = async <T = SimpleObject>(
  collectionName: string,
  docId: string,
  data: T,
): Promise<void> => {
  const firestore = getFirestore();
  const docRef = doc(firestore, collectionName, docId);
  return setDoc(
    docRef,
    {
      ...(data || {}),
    },
    { merge: true },
  );
};

const updateDocumentAttributes = async <T = SimpleObject>(
  collectionName: string,
  docId: string,
  data: T,
): Promise<void> => {
  const firestore = getFirestore();
  const docRef = doc(firestore, collectionName, docId);
  return updateDoc(docRef, {
    ...(data || {}),
  });
};

/**
 * @deprecated
 * use `updateConversationAttributes` instead
 */
export const setConversationAttributes = (
  encounterId: string,
  data: Firestore_Soap_Conversation,
): Promise<void> =>
  setDocumentAttributes<Firestore_Soap_Conversation>(
    FirestoreCollection.CONVERSATIONS,
    encounterId,
    data,
  );

export const updateConversationAttributes = (
  encounterId: string,
  data: Firestore_Soap_Conversation,
): Promise<void> =>
  updateDocumentAttributes<Firestore_Soap_Conversation>(
    FirestoreCollection.CONVERSATIONS,
    encounterId,
    data,
  );

/**
 * @deprecated
 * use `updateEncounterAttributes` instead
 */
export const setEncounterAttributes = async (
  encounterId: string,
  data: Firestore_Soap_Encounter,
): Promise<void> =>
  setDocumentAttributes<Firestore_Soap_Encounter>(
    FirestoreCollection.ENCOUNTERS,
    encounterId,
    data,
  );

export const updateEncounterAttributes = async (
  encounterId: string,
  data: Firestore_Soap_Encounter,
): Promise<void> =>
  updateDocumentAttributes<Firestore_Soap_Encounter>(
    FirestoreCollection.ENCOUNTERS,
    encounterId,
    data,
  );

export const batchUpdateEncounterAttributes = async (
  encounterIds: string[],
  data: Firestore_Soap_Encounter,
): Promise<void> => {
  // NOTE: Can support upto 500 operation at a time.
  const firestore = getFirestore();
  const batch = writeBatch(firestore);
  encounterIds?.forEach((encId) => {
    const docRef = doc(firestore, FirestoreCollection.ENCOUNTERS, encId);
    batch.update(docRef, data);
  });
  await batch.commit();
};

/**
 * @deprecated
 * use `updateUserAccountAttributes` instead
 */
export const setUserAccountAttributes = async (
  userId: string,
  data: Firestore_User_Account,
): Promise<void> => {
  try {
    setDocumentAttributes<Firestore_User_Account>(
      FirestoreCollection.USER_ACCOUNTS,
      userId,
      data,
    );
  } catch (err) {
    sentryError(err);
  }
};

export const updateUserAccountAttributes = async (
  userId: string,
  data: Firestore_User_Account,
): Promise<void> => {
  try {
    updateDocumentAttributes<Firestore_User_Account>(
      FirestoreCollection.USER_ACCOUNTS,
      userId,
      data,
    );
  } catch (err) {
    sentryError(err);
  }
};

export const subscribeUserAccountListener = async (
  userId: string,
  onSnapshotCallback: (
    snapshot: DocumentSnapshot<Firestore_User_Account>,
  ) => void,
  onErrorCallback: (error: FirestoreError) => void,
): Promise<Unsubscribe | undefined> => {
  const firestore = getFirestore();
  try {
    const userDocRef = doc(
      firestore,
      FirestoreCollection.USER_ACCOUNTS,
      userId,
    );
    const unsubscribeListener = onSnapshot<Firestore_User_Account>(
      userDocRef,
      {},
      onSnapshotCallback,
      onErrorCallback,
    );
    return unsubscribeListener;
  } catch (err) {
    console.error("Failed to log in to firebase client SDK", err);
    sentryError(err);
    return;
  }
};

/**
 * Saves user events to firestore and returns the events that were saved
 */
export const saveUserEvents = async (
  encounterId: string,
  userEvents: UserEventWithTimestamp[],
): Promise<UserEventWithTimestamp[]> => {
  const firestore = getFirestore();
  const eventsToSave = [...(userEvents || [])];
  // fix events to save
  // we cannot have any undefined events or undefined attributes in each event payload
  const fixedEventsToSave = cloneDeep(eventsToSave)
    .filter(
      (e) => !!e, // remove any undefined or falsy events
    )
    .map((e) => {
      Object.entries(e)?.forEach(([key, val]) => {
        // removed any undefined attributes
        if (val === undefined) {
          // @ts-ignore
          delete e[key];
        }
      });
      return e;
    });
  const docRef = doc(
    firestore,
    "provider-encounters",
    encounterId,
    "metadata",
    "events",
  ); // TODO move this collection name to soap-common
  // normally we should avoid using `setDoc` but in this case we need to create this document if it does not exist
  await setDoc(
    docRef,
    {
      soapDashboardEditor: arrayUnion(...fixedEventsToSave),
    },
    { merge: true },
  );
  return eventsToSave;
};

/**
 * Listen to the entire list of encounters for the current date (for non-integrated users)
 * Imp: Make sure query is in-line with the /notes/fetch call
 */
export const subscribeEncounterListListener = async (
  userId: string,
  currentSelectedDate: Date,
  onSnapshotCallback: (
    snapshot: QuerySnapshot<Firestore_Soap_Encounter>,
  ) => void,
  onErrorCallback: (error: FirestoreError) => void,
): Promise<Unsubscribe | undefined> => {
  const firestore = getFirestore();
  try {
    if (!userId || !currentSelectedDate) return;

    const queryStartDate = DateTime.fromJSDate(currentSelectedDate)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      .toJSDate();
    const queryEndDate = DateTime.fromJSDate(currentSelectedDate)
      .set({
        hour: 23,
        minute: 59,
        second: 59,
      })
      .toJSDate();
    const limitSize = 1000; // Reasonable upper limit per day.
    // IMP! This query should be in sync with the query used to fetch encounters in the /notes/fetch api endpoint.
    const encQuery = query(
      collection(firestore, FirestoreCollection.ENCOUNTERS),
      where("source", "==", "CLINICIAN_RECORDER"),
      where("subject", "==", userId),
      where("callInitiated", ">=", queryStartDate),
      where("callInitiated", "<=", queryEndDate),
      orderBy("callInitiated", "asc"),
      limit(limitSize),
    );
    const unsubscribeListener = onSnapshot<Firestore_Soap_Encounter>(
      encQuery,
      {},
      onSnapshotCallback,
      onErrorCallback,
    );
    return unsubscribeListener;
  } catch (err) {
    console.error("Failed to subscribe to to review page 1 listener", err);
    sentryError(err);
    return;
  }
};

/**
 * Subscribe to each individual recorded encounter in the current shown scheduled appointment list
 * (Integrated user list)
 */
export const subscribeToScheduledEncounters = async (
  encounterIds: string[],
  onSnapshotCallback: (
    snapshot: DocumentSnapshot<Firestore_Soap_Encounter>,
  ) => void,
  onErrorCallback: (error: FirestoreError) => void,
): Promise<Unsubscribe[] | undefined> => {
  const firestore = getFirestore();
  try {
    const unsubListeners = [];
    for await (const encId of encounterIds) {
      if (!encId) continue;
      unsubListeners?.push(
        onSnapshot<Firestore_Soap_Encounter>(
          doc(firestore, FirestoreCollection.ENCOUNTERS, encId),
          {},
          onSnapshotCallback,
          onErrorCallback,
        ),
      );
    }
    return unsubListeners;
  } catch (error) {
    sentryError(error);
  }
  return;
};

/**
 * Updates user profile.
 *
 * Debounced so that it can only be called once every second.
 **/
export const updateUserProfile = (
  userProfile: UserProfile,
): Promise<AxiosResponse<void | ErrorResponse>> =>
  postToRoute("/api/user/updateProfile", {
    ...userProfile,
  });
