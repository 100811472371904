import { init } from "@abridge/next-firebase-auth";
import { getApps } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";
import { isClientSide } from "./common";

const TWELVE_DAYS_IN_MS = 12 * 60 * 60 * 24 * 1000;

export const firebaseClientInitConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY || "",
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID || "",
};

const initAuth = (): void => {
  const privateKey = process.env.FIREBASE_PRIVATE_KEY
    ? JSON.parse(process.env.FIREBASE_PRIVATE_KEY || "{ privateKey: null }")
        ?.privateKey || "undefined"
    : "";

  init({
    debug: process?.env?.NODE_ENV !== "production",
    authPageURL: "/login",
    appPageURL: "/home",
    loginAPIEndpoint: "/api/login",
    logoutAPIEndpoint: "/api/logout",
    useFirebaseAdminDefaultCredential: false,
    firebaseAdminInitConfig: {
      credential: {
        projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || "",
        clientEmail: process.env.FIREBASE_CLIENT_EMAIL || "",
        // Using JSON to handle newline problems when storing the
        // key as a secret in Vercel. See:
        // https://github.com/vercel/vercel/issues/749#issuecomment-707515089
        privateKey,
      },
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL || "",
    },
    firebaseClientInitConfig,
    cookies: {
      name: "abridge__notes--",
      keys: [
        process.env.COOKIE_SECRET_CURRENT,
        process.env.COOKIE_SECRET_PREVIOUS,
      ],
      httpOnly: true,
      maxAge: TWELVE_DAYS_IN_MS,
      overwrite: true,
      path: "/",
      sameSite: "strict",
      secure: process.env.NEXT_PUBLIC_COOKIE_SECURE === "true",
      signed: true,
    },
  });

  // Enable long polling to resolve network issues at Kaiser
  // https://github.com/firebase/firebase-js-sdk/issues/1674
  // https://groups.google.com/g/firebase-talk/c/7CNG0JY7P1E?pli=1
  // https://www.youtube.com/watch?v=GUdSbhZmDtg&t=116s
  if (isClientSide()) {
    const apps = getApps();
    const app = apps[0];
    if (app) {
      initializeFirestore(app, {
        experimentalAutoDetectLongPolling: true,
      });
      console.log(
        "Set experimentalAutoDetectLongPolling = true when initializing firestore",
      );
    }
  }
};

export default initAuth;
