import { sentryError } from "@integrations/sentry";

export const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  const b64 = window.btoa(binary);
  return b64;
};

const blobToUint8Array = async (blob: Blob): Promise<Uint8Array> =>
  new Uint8Array(await blob.arrayBuffer());

const base64ToUint8Array = (base64: string) => {
  const binary = atob(base64);
  const len = binary.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) bytes[i] = binary.charCodeAt(i);
  return bytes;
};

const importPublicKey = async (key: string): Promise<CryptoKey> => {
  const pem = atob(key);

  const pemHeader = "-----BEGIN PUBLIC KEY-----";
  const pemFooter = "-----END PUBLIC KEY-----";

  const pemContents = pem.substring(
    pemHeader.length,
    pem.length - pemFooter.length - 1,
  );

  const binaryStr = window.atob(pemContents);

  const str2ab = (str: string): Uint8Array => {
    const buffer = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
      buffer[i] = str.charCodeAt(i);
    }
    return buffer;
  };

  const buff = str2ab(binaryStr);

  return window.crypto.subtle.importKey(
    "spki",
    buff,
    {
      name: "RSA-OAEP",
      hash: "SHA-256",
    },
    true,
    ["encrypt", "wrapKey"],
  );
};

export interface EncryptedAudioData {
  encKey: string;
  encryptedAudioData: ArrayBuffer;
  signatures: {
    data: string;
    keys: string;
  };
}

export const encryptAudioData = async (
  blob: Blob,
  b64PublicRSAKey: string,
): Promise<EncryptedAudioData | undefined> => {
  try {
    if (!window.crypto || !window.crypto.subtle) {
      throw new Error("Browser does not support crypto module");
    }
    // 1. Create symmetric key.
    const symKey = await window.crypto.subtle.generateKey(
      {
        name: "AES-CBC",
        length: 256,
      },
      true,
      ["encrypt"],
    );

    // 2. Encrypt audio data with symmetric key.
    const iv = window.crypto.getRandomValues(new Uint8Array(16));

    const bufferData = await blobToUint8Array(blob);

    const encryptedAudioData: ArrayBuffer = await window.crypto.subtle.encrypt(
      {
        name: "AES-CBC",
        iv: iv,
      },
      symKey,
      bufferData,
    );

    // 2.2 Generate HMAC key for authenticity
    const hmacKey = await window.crypto.subtle.generateKey(
      {
        name: "HMAC",
        hash: { name: "SHA-256" },
      },
      true,
      ["sign"],
    );

    // Sign encryptedAudioData with hmac key
    const audioDataSignature = await window.crypto.subtle.sign(
      {
        name: "HMAC",
      },
      hmacKey,
      encryptedAudioData,
    );

    // 3. Encrypt symmetric key + HMAC key with publicRSAKey.
    // 3.1 Import publicKey.
    const publicKey = await importPublicKey(b64PublicRSAKey);
    // 3.2 Encrypt symKey+hmacKey with receipient's public key.
    const exportedSymKey = await window.crypto.subtle.exportKey("raw", symKey);
    const exportedHmacKey = await window.crypto.subtle.exportKey(
      "raw",
      hmacKey,
    );
    let encryptedKeys: ArrayBuffer | string =
      await window.crypto.subtle.encrypt(
        {
          name: "RSA-OAEP",
        },
        publicKey,
        base64ToUint8Array(
          btoa(
            JSON.stringify({
              encKey: arrayBufferToBase64(exportedSymKey),
              hmacKey: arrayBufferToBase64(exportedHmacKey),
              iv: arrayBufferToBase64(iv),
            }),
          ),
        ),
      );
    encryptedKeys = arrayBufferToBase64(encryptedKeys);

    // Sign encrypted keys
    const encKeysSignature = await window.crypto.subtle.sign(
      {
        name: "HMAC",
      },
      hmacKey,
      base64ToUint8Array(encryptedKeys),
    );

    // 4. Return the encrypted audio, encrypted symmetric key, and both signatures.
    return {
      encryptedAudioData,
      encKey: encryptedKeys,
      signatures: {
        data: arrayBufferToBase64(audioDataSignature),
        keys: arrayBufferToBase64(encKeysSignature),
      },
    };
  } catch (err) {
    console.error(err);
    sentryError(err);
    return undefined;
  }
};

export * from "./generateKeyPair";
