import {
  LDMultiKindContext,
  useFlags,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import {
  getKnownFlagSchema,
  KnownFlagKey,
  KnownFlagValue,
} from "./known-flags";

const warnNotInitializedDefaultValue = (client: string) => {
  // eslint-disable-next-line no-console
  console.warn(
    `Launch Darkly ${client} client not initialized, returning default value.`,
  );
};

/**
 * Hook for using a {@link KnownFlagKey known feature flag}.
 */
export const useKnownFeatureFlag = <K extends KnownFlagKey>(
  flag: K,
  defaultValue: KnownFlagValue<K>,
): { value: KnownFlagValue<K>; initialized: boolean } => {
  const client = useLDClient();
  const ldContext = (client?.getContext() ?? {}) as LDMultiKindContext;
  const ldContextUser = ldContext?.["user"];
  const flags = useFlags();

  let value = defaultValue;
  const rawFlagValue = flags[flag];

  if (!ldContextUser || !flags) {
    warnNotInitializedDefaultValue("sdk");
  }

  if (typeof rawFlagValue !== "undefined") {
    const schema = getKnownFlagSchema(flag);
    const parseResult = schema.safeParse(rawFlagValue);

    if (parseResult.success) {
      value = parseResult.data as KnownFlagValue<K>;
    } else {
      // eslint-disable-next-line no-console
      console.error(`Flag '${flag}' failed to parse`, parseResult.error);
    }
  }

  return { value, initialized: true };
};

export * from "./known-flags";
