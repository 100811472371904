import { sentryError } from "@integrations/sentry";
import { isClientSide } from "./common";

let isFirstTab = true;

export const triggerTabCheck = (): void => {
  try {
    const broadcast = isClientSide()
      ? window?.BroadcastChannel
        ? new BroadcastChannel("abr_multitab_test")
        : undefined
      : undefined;
    if (!broadcast) return;
    broadcast.onmessage = (event) => {
      if (event.data === "first_tab") {
        broadcast?.postMessage(`i_am_first_tab`);
        isFirstTab = true;
      }
      if (event.data === `i_am_first_tab`) {
        isFirstTab = false;
      }
    };
    broadcast?.postMessage("first_tab");
  } catch (err) {
    sentryError(err);
  }
};

export const isDuplicateTab = (): boolean => {
  return !isFirstTab;
};
