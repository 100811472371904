export const defaultErrorMessage =
  "Sorry! We’re not sure what went wrong. If you keep seeing this message please contact us for assistance.";

// https://firebase.google.com/docs/reference/js/firebase.auth.ConfirmationResult#error-codes
export const phoneVerificationErrorMessages: { [key: string]: string } = {
  "auth/invalid-verification-code":
    "The SMS verfication code entered is incorrect or invalid. Please try again.",
  "invalid-code":
    "The verfication code entered is incorrect or invalid. Please try again.",
  "auth/missing-verification-code":
    "No SMS verification code provided. Please try again.",
  "auth/user-disabled":
    "Your account has been disabled. Please contact us for assistance.",
};

export const phoneNumberErrorMessages: { [key: string]: string } = {
  "auth/captcha-check-failed":
    "There was an error with the reCaptcha check. Please refresh browser and try again.",
  "auth/invalid-phone-number": "The phone number is invalid. Please try again.",
  "auth/missing-phone-number": "The phone number is missing. Please try again.",
  "auth/quota-exceeded": defaultErrorMessage,
  "auth/too-many-requests":
    "Sorry! We have received too many login attempts from this phone number. Please try again after 1 hour.",
  "auth/user-disabled":
    "Your account has been disabled. Please contact support for assistance.",
};

export const TIMED_OUT_ERROR =
  "Your session has expired. Please sign in again to continue.";
